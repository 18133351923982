<!-- eslint-disable no-undef -->
<script setup lang="ts">
import { watch, } from 'vue';
import { header } from './menu.ts';
import { navigateTo } from '#imports'
import { useCookie } from 'nuxt/app'

// 根据是否登录来更新头部菜单
const updateMenu = () => {
  const data = useCookie('auth.token')

  if (data.value != undefined) {
    header.functional = [
      {
        // Todo 动态化地址
        href: '/pm/admin/project',
        name: '控制台',
      },
    ]
  }
}

updateMenu()

const title = header.title
// const bg = header.bg
const logo = header.logo
const navigation = header.navigation
const functional = header.functional

const route = useRoute();

let routeHref = ref()
watch(() => route.path, () => {
  routeHref.value = route.path
}, { deep: true, immediate: true })


const goto = (path: string) => {
  navigateTo(path)
}
</script>

<template>
<div class="header">
  <div class="header-l">
    <img class="header-logo" :src="logo" />
    <span class="header-title">{{ title }}</span>

    <nav>
      <ul style="margin-left:50px" class="header-navigation">
        <li style="margin-left: 10px;" v-for="(item, index) in navigation" :key="index">
          <a :class="item.href === routeHref ? 'active nav-item' : 'nav-item'" @click="goto(item.href)"
            style="cursor: pointer;">
            {{ item.name }}
            <span v-if="item.subMenu">
              <t-icon name="caret-down-small" size="small" />
            </span>
            <ul class="dropdown-menu" v-if="item.subMenu">
              <li v-for="(subItem, subIndex) in item.subMenu" :key="subIndex">
                <a style="cursor: pointer;" @click.stop="goto(subItem.href)">{{ subItem.name }}</a>
              </li>
            </ul>
          </a>
        </li>
      </ul>

      <ul class=" header-functional">
        <!-- <li>
            <button ref="colorModeBtn" @click="colorModeClick" id="theme_button" data-theme="dark"
              style="transform: rotateZ(0deg);" class="color-mode-btn">
              <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="32" height="32"
                viewBox="0 0 24 24" class="iconify iconify--material-symbols">
                <path fill="currentColor" :d="colorModePath">
                </path>
              </svg>
            </button>
          </li> -->
        <li v-for="(item, index) in functional" :key="index" class="fun-item">
          <a class="login" style="cursor: pointer;" @click="goto(item.href)">{{ item.name }}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<div style="height:calc(100vh - 60px);width: 100%; margin-top: 60px;">
  <slot />
</div>
</template>

<style scoped>
.header {
  padding: 10px;
  /* background: v-bind(bg); */
  /* background: linear-gradient(#000000, rgb(21 28 36 / 1)); */
  background: linear-gradient(#0c1439, #0a1237);
  min-height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999;
}

.header-l {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-logo {
  display: inline-block;
  width: 70px;
}

.header-title {
  white-space: nowrap;
  width: 15em;
  margin-left: 1.5em;
  font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: clamp(1rem, 0.818rem + 0.91vw, 1.5rem);
  color: #c4c7c5;
  text-align: left;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header-navigation,
.header-functional {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.nav-item,
.fun-item {
  display: flex;
  margin-left: 0.9em;
  padding: 0 0.3em;
  color: #c4c7c5;
  text-decoration: none;
  position: relative;
  font-size: 1rem;
  justify-content: center;
}


.nav-item:hover,
.fun-item:hover,
.nav-item:focus {
  color: white;
}

.dropdown-menu {
  /* margin-top: 10px; */
  display: none;
  position: relative;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0.3125em 0;
  background-color: #aaa;
  min-width: 160px;
  box-shadow: 0em 0.5em 1em 0em rgba(0, 0, 0, 0.2);
  border-radius: 1em;
  z-index: 1000;
}

.dropdown-menu a {
  display: block;
  padding: 0.5rem 1em;
  text-decoration: none;
  color: #fff;
}

.dropdown-menu a:hover {
  background-color: #1a1919;
  margin-left: 0.3125em;
  margin-right: 0.3125em;
  border-radius: 1em;
  color: white;
}

/* 显示下拉菜单 */
.nav-item:hover .dropdown-menu {
  display: block;
  position: absolute;
}

/* 清除浮动 */
.header::after {
  content: "";
  display: table;
  clear: both;
}

.register:hover {
  background-color: #0056b3;
  border-color: #004080;
}


@media screen and (max-width: 1510px) {
  nav {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}


@media screen and (max-width: 1055px) {

  .header-navigation,
  .header-functional {
    flex-wrap: nowrap;
    align-items: center;
  }

  .nav-item,
  .fun-item {
    flex-shrink: 0;
    white-space: nowrap;
  }

  .dropdown-menu {
    position: static;
  }
}

.color-mode-btn {
  transition: transform .3s;
}

.active {
  /* background-color: red; */
  color: rgb(236, 232, 232);
  font-weight: 700;
}
</style>
